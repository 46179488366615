import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

/** MUI Components */
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Card, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

/** MUI Icons */
import TaskAltIcon from '@mui/icons-material/TaskAlt';

/** Helpers */
import { imprimirTicket } from '../../helpers/printer';
import moment from 'moment';
import 'moment/locale/es';
import { getDBData } from '../../helpers/animalesDB';
// import { useIndexedDB } from 'react-indexed-db-hook';

const Lectura = ({parametros, estancias, letras, colores, grupos, secciones, sexos, etapas, etapa, actualizarApp}) => {

  // const db = useIndexedDB('animales');

  const navigate = useNavigate();
  /** localStorage Gets */
  // const parametros = JSON.parse(localStorage.getItem('parametros'));
  const [animales, setAnimales] = useState([]);
  const [animalesDispositivo, setAnimalesDispositivo] = useState([]);
  const [actividad, setActividad] = useState([]);
  const [actividadDispositivo, setActividadDispositivo] = useState([]);

  /** Available states */
  const [estancia, setEstancia] = useState(parametros?.estancia);
  const [esquila, setEsquila] = useState(parametros?.esquila);
  const [serie, setSerie] = useState(parametros?.serie);
  const [color, setColor] = useState(parametros?.color);
  const [letra, setLetra] = useState(null);
  const [grupo, setGrupo] = useState(null);
  const [numero, setNumero] = useState();

  const [viewList, setViewList] = useState(false);
  const [filteredAnimales, setFilteredAnimales] = useState(false);
  const [animalSelected, setAnimalSelected] = useState(null);

  const [animalCargaManual, setAnimalCargaManual] = useState(
    {
      "id": null,
      "senasa": "", // TODO Desarrollar alguna funcion que lo genere a partir de parametros definidos en el documento.
      "estancia_id": parametros?.estancia,
      "serie": parametros?.serie,
      "letra_id": null,
      "numero": "",
      "category_id": "",
      "grupo_id": "",
      "sex_id": "",
      "color_id": "",
      "caravana_id": null,
      "section_id": "",
      "nacimiento":"2017"
    }
  );

  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
  const [openDialogReConfirm, setOpenDialogReConfirm] = useState(false);
  const [openDialogTipoActividad, setOpenDialogTipoActividad] = useState(false);
  const [openDialogCargaManual, setOpenDialogCargaManual] = useState(false);

  const [openConfirmedGreen, setOpenConfirmedGreen] = useState(false);

  const [repetida, setRepetida] = useState(false);

  const actualizarAnimales = async () => {

    let storageData = null;
    // let storageData = localStorage.getItem('animales-db');
    // if (storageData) { setAnimales(JSON.parse(storageData)) };
    // db.getAll().then(items => setAnimales(items),reason => console.log('DB Error:', reason));
    const animalesDB = await getDBData('animales');
    setAnimales(animalesDB);

    storageData = localStorage.getItem('animales-dispositivo');
    if (storageData) { setAnimalesDispositivo(JSON.parse(storageData)) };

  }

  const actualizarActividad = () => {
    let storageData = null;

    storageData = localStorage.getItem('actividad-db');
    if (storageData) { setActividad(JSON.parse(storageData)) };

    storageData = localStorage.getItem('actividad-dispositivo');
    if (storageData) { setActividadDispositivo(JSON.parse(storageData)) };

  }

  /** Handlers */
  const handleDialogConfirmOpen = () => {
    setOpenDialogConfirm(true);
  };

  const handleClose = () => {
    setOpenDialogConfirm(false);
    setOpenDialogReConfirm(false);
    setOpenDialogTipoActividad(false);
  };

  const handleChangeNumero = (e) => {
    setNumero(e.target.value);
    setViewList(false);
  };

  const filterListItems = () => {
    if (numero > 0) {
      const animalesTotales = [...animales, ...animalesDispositivo];
      const filtered = animalesTotales.filter(
        (animal) => {
          return (animal.numero == numero
            && animal.estancia_id == estancia
            && animal.serie == serie
            && ((animal.color_id == color && color > 0) || (color == '0'))
          )
        }
      );
      setFilteredAnimales(filtered);
      setViewList(true);
    } else {
      setViewList(false);
    };
  };

  const keyPress = (e) => {
    if(e.keyCode == 13){
      filterListItems();
    };
    return true;
  };

  const handleConfirm = async (item) => {

    if (!etapa || etapa == undefined) {
      return setOpenDialogTipoActividad(true);
    };

    setAnimalSelected(item);

    const actividadTotal = actividadDispositivo.concat(actividad);
    const animalConActividad = await actividadTotal.find(
        (actividadActual) => {
        return (
          // (actividadActual?.animal_id == item?.id)
          (actividadActual?.senasa == item?.senasa) // Para los animales nuevos, no hay id
            && actividadActual?.estancia_id == estancia
            && actividadActual?.esquila_id == esquila
            && actividadActual?.tipo == etapa.name
          )
        }
      );

    if (animalConActividad) {
      setRepetida(true);
      setOpenDialogReConfirm(true);
      handleDialogConfirmOpen();
    } else {
      handleConfirmed(item);
    }
  };

  const actividadNueva = (item) => {

    let ahora = moment(new Date()).locale('es');

    let actividadConfirmada = {

      id: null,
      animal_id: item?.id,
      // comentarios : null,
      esquila_id : esquila,
      // estadoregistro_id : "1",
      estancia_id : estancia,
      // fecha :  "2021-10-27 00:00:00.000",
      // hora : "16:42:16.0000000",
      lote_id : item?.lote_id,
      section_id : item?.section_id,
      senasa: item?.senasa,
      datetime: ahora.format('YYYY-MM-DD HH:mm:ss'),
      fecha: ahora.format('YYYY-MM-DD'),
      hora: ahora.format('HH:mm:ss'),
      finura: item.Mic_Ave,
      cv: item.CV_Mic,

      tipo: etapa?.name.toUpperCase()

    };

    localStorage.setItem('actividad-dispositivo', JSON.stringify([...actividadDispositivo, actividadConfirmada]));
    actualizarActividad();
  };

  const handleConfirmed = (item) => {
    const actividad = actividadNueva(item);
    handleClose();
    setNumero('');
    setOpenDialogReConfirm(false);
    setOpenConfirmedGreen(true);
    setTimeout(() => {
      setOpenConfirmedGreen(false);
    }, 500);
    return true;
  };

  const handleCloseDialogTipoActividad = () => {
    return setOpenDialogTipoActividad(false);
  }

  const handleSeleccionarTipoActividadAndConfirm = (item) => {
    handleConfirm(animalSelected);
    localStorage.setItem('etapa', JSON.stringify(item));
    actualizarApp();
  };

  const handleCargarAnimal = () => {

    setAnimalCargaManual({...animalCargaManual, numero: numero})

    const senasa = generarSenasa(animalCargaManual);

    let animalParaCargar = {...animalCargaManual,
      "numero": numero,
      "senasa" : senasa
    }

    localStorage.setItem('animales-dispositivo', JSON.stringify([...animalesDispositivo, animalParaCargar]));
    actualizarAnimales();
    setAnimalSelected(animalParaCargar);
    handleConfirmed(animalParaCargar);

    setOpenDialogCargaManual(false);
  }

  const refreshParametros = () => {
    const parametros = JSON.parse(localStorage.getItem('parametros'));
    setEstancia(parametros?.estancia);
    setSerie(parametros?.serie);
    setColor('0');
  };

  const generarSenasa = (animal) => {
    const cuigEstanciaAnimal = estancias.find((item) => {return item.id === animal.estancia_id})
    const letraAnimal = letras.find((item) => {return item.id === animal.letra_id})
    const colorAnimal = colores.find((item) => {return item.id === animal.color_id})
    const numeroAnimal = numero.toString().padStart(6, '0')
    return `${cuigEstanciaAnimal.cuig}${animal.serie}${(letraAnimal?.letra) ? letraAnimal?.letra : ' '}${colorAnimal.codigo}${numeroAnimal}`
  }

  const generarTicket = (animal) => {
    console.log('generarTicket', animal, etapa.name, repetida, grupos);
    imprimirTicket(animal,etapa.name,repetida,grupos);
    refreshParametros();
  };

  /** Side effects */
  useEffect(() => {
    if (!!animalSelected && !!openConfirmedGreen) {
      generarTicket(animalSelected);
      setRepetida(false);
    }
  },[animalSelected, openConfirmedGreen]);

  useEffect(() => {
    filterListItems();
  }, [estancia, serie, color, openDialogTipoActividad]);

  useEffect(() => {
    setEstancia(parametros?.estancia);
    setEsquila(parametros?.esquila);
    setSerie(parametros?.serie);
    setColor('0');
  }, [parametros]);

  useEffect(() => {
    actualizarAnimales();
    actualizarActividad();
  }, []);

  return(
    <Container disableGutters>

      {/** Buscador de animales */}
      <Box
        sx={{
          bgcolor: '#cfe8fc',
          height: '100vh',
          padding: '20px'
        }} >

        <Typography variant="h6" gutterBottom>
          Lectura
        </Typography>

        <Grid container spacing={2}>
        {/* <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="esquila">
                Esquila
              </InputLabel>
              <NativeSelect
                value={esquila}
                onChange={(e) => setEsquila(parametros['esquila'] = e.target.value)}
                inputProps={{
                  name: 'esquila',
                  id: 'esquila',
                }}
              >
                {esquilas && esquilas.map((item, index) => {
                  return (<option value={item.id} key={item.id}>{item.descripcion}</option>)
                })
                }
              </NativeSelect>
            </FormControl>
          </Grid> */}
          <Grid item xs={8}>
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="estancia">
                Estancia
              </InputLabel>
              <NativeSelect
                value={estancia}
                onChange={(e) => setEstancia(e.target.value)}
                inputProps={{
                  name: 'estancia',
                  id: 'estancia',
                }}
              >
                {estancias && estancias.map((item, index) => {
                  return (<option value={item.id} key={item.id}>{item.descripcion}</option>)
                })
                }
              </NativeSelect>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <TextField label="Serie" variant="standard"
              value={serie}
              onChange={(e) => setSerie(e.target.value)}
            />
          </Grid>
          <Grid item xs={8}>
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="color">
                Color
              </InputLabel>
              <NativeSelect
                value={color}
                onChange={(e) => setColor(e.target.value)}
                inputProps={{
                  name: 'color',
                  id: 'color',
                }}
              >
                <option value={0} key={'color-0'}>{`Filtre por color`}</option>
                {colores && colores.map((item, index) => {
                  return (<option value={item.id} key={item.id}>{item.descripcion}</option>)
                })
                }
              </NativeSelect>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              autoFocus
              type='none'
              label="Numero"
              variant="standard"
              sx={{ width: '100%' }}
              inputProps={{ inputMode: 'numeric'}}
              value={numero ? numero : ''}
              // onBlur={handleChangedNumero}
              onChange={handleChangeNumero}
              onKeyDown={keyPress}
            />
          </Grid>
        </Grid>

        {/** Lista de acciones */}
        {numero ?
        <Box m={2}>
          {viewList ?
          <>
            <List>
              {filteredAnimales && filteredAnimales.map((item, index) => {
                return (
                  <ListItem key={index}>
                    <Card key={index} onClick={() => { handleConfirm(item) }} style={
                      {
                        borderStyle: 'solid',
                        borderRadius: '5px',
                        width: '100%',
                        height: '80px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center'
                      }}>
                      <Typography fontSize={'1.5em'} align='center'>{item.senasa}</Typography>
                    </Card>
                  </ListItem>
                )
              })}
            </List>
            {filteredAnimales.length == 0 &&
            <Typography variant="body2">
              No se encontraron resultados
            </Typography>
            }
                <Button variant="contained"
                  sx={{
                    marginTop: 10,
                    width: '100%',
                    height: '60px',
                    marginBottom: '50px',
                  }}
                  onClick={() => {
                    setAnimalCargaManual({ ...animalCargaManual, estancia_id: estancia, color_id: color, serie: serie })
                    setOpenDialogCargaManual(true);
                  }}>¿Realizar una carga manual?</Button>
          </>
          :
          <></>
          }
        </Box>
        :
        <></>
        }

      </Box>

      {/** Dialog Confirmacion */}
      <Dialog
        open={openDialogConfirm}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
            {`¿Confirma ${etapa?.name}?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {openDialogReConfirm ?
              <Typography>{`${animalSelected && animalSelected.senasa} ya realizó ${etapa.name} anteriormente.`}</Typography>
            :
              <Typography>{`${animalSelected && animalSelected.senasa}`}</Typography>
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={() => handleConfirmed(animalSelected)} autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      {/** Dialog Confirmado! */}
      <Dialog
        open={openConfirmedGreen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen
      >
        <DialogContent sx={{backgroundColor: 'green'}}>
          <DialogContentText id="alert-dialog-description">
            <TaskAltIcon style={{
              width: '100%',
              height: '100%',
              marginTop: '50%',
              color: 'white',
            }} />
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {/** Dialog Seleccione un tipo de actividad */}
      <Dialog
        open={openDialogTipoActividad}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
            {`Seleccione una etapa`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {etapas && etapas.map((item, index) => {
              return (
                <Button variant="contained"
                  key={index}
                  sx={{
                    marginTop: 4,
                    marginBottom: 4,
                    width: '200px',
                    height: '60px',
                  }}
                  value={item.id}
                  onClick={() => {
                    handleSeleccionarTipoActividadAndConfirm(item);
                    setOpenDialogTipoActividad(false);
                  }}
                >
                  {item.name}
                </Button>
              )
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogTipoActividad}>Volver</Button>
        </DialogActions>
      </Dialog>

      {/** Dialog Formulario de Carga Manual */}
      <Dialog
        open={openDialogCargaManual}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
            {`Carga manual de animal`}
        </DialogTitle>
        <DialogContent>

          <Box>

            <Grid container spacing={2}>

              <Grid item xs={12}>

                {/** Estancia */}
                <FormControl fullWidth>
                  <InputLabel variant="standard" htmlFor="estancia_id">
                    Estancia
                  </InputLabel>
                  <NativeSelect
                    value={animalCargaManual.estancia_id}
                    onChange={(e) => setAnimalCargaManual({...animalCargaManual, estancia_id: e.target.value})}
                    inputProps={{
                      name: 'estancia_id',
                      id: 'estancia_id',
                    }}
                    // disabled={true}
                  >
                    <option value={0} key={"Estancias"}>{"Estancias"}</option>
                    {estancias && estancias.map((item, index) => {
                      return (<option value={item.id} key={index}>{item.descripcion}</option>)
                    })
                    }
                  </NativeSelect>
                </FormControl>

              </Grid>

              <Grid item xs={4}>

                {/** Serie */}
                <TextField label="Serie" variant="standard"
                  value={serie}
                  onChange={(e) => setAnimalCargaManual({...animalCargaManual, serie: e.target.value})}
                  // disabled={true}
                />

              </Grid>

              <Grid item xs={4}>

                {/** Numero */}
                <TextField
                  label="Numero"
                  variant="standard"
                  sx={{ width: '100%' }}
                  inputProps={{ inputMode: 'numeric'}}
                  value={numero}
                  onChange={(e) => setAnimalCargaManual({...animalCargaManual, numero: e.target.value})}
                  // disabled={true}
                />

              </Grid>

              <Grid item xs={4}>
                {/** Letra */}
                <FormControl fullWidth>
                  <InputLabel variant="standard" htmlFor="letra_id">
                    Letra
                  </InputLabel>
                  <NativeSelect
                    value={animalCargaManual.letra_id}
                    onChange={(e) => setAnimalCargaManual({...animalCargaManual, letra_id: e.target.value})}
                    inputProps={{
                      name: 'letra_id',
                      id: 'letra_id',
                    }}
                  >
                    <option value={0} key={"Letras"}>{"Letras"}</option>
                    <option value={" "} key={"0"}>{' '}</option>
                    {letras && letras.map((item, index) => {
                      return (<option value={item.id} key={index}>{item.letra}</option>)
                    })
                    }
                  </NativeSelect>
                </FormControl>
              </Grid>

              <Grid item xs={12}>

                {/** Color */}
                <FormControl fullWidth>
                  <InputLabel variant="standard" htmlFor="color_id">
                    Color
                  </InputLabel>
                  <NativeSelect
                    value={animalCargaManual.color_id}
                    onChange={(e) => setAnimalCargaManual({...animalCargaManual, color_id: e.target.value})}
                    inputProps={{
                      name: 'color_id',
                      id: 'color_id',
                    }}
                  >
                    <option value={0} key={"Colores"}>{"Colores"}</option>
                    {colores && colores.map((item, index) => {
                      return (<option value={item.id} key={index}>{item.descripcion}</option>)
                    })
                    }
                  </NativeSelect>
                </FormControl>

              </Grid>

              <Grid item xs={12}>

                {/** Seccion */}
                <FormControl fullWidth>
                  <InputLabel variant="standard" htmlFor="section_id">
                    Sección
                  </InputLabel>
                  <NativeSelect
                    value={animalCargaManual.section_id}
                    onChange={(e) => setAnimalCargaManual({...animalCargaManual, section_id: e.target.value})}
                    inputProps={{
                      name: 'section_id',
                      id: 'section_id',
                    }}
                  >
                    <option value={0} key={"Secciones"}>{"Secciones"}</option>
                    {secciones && secciones.map((item, index) => {
                      return (<option value={item.id} key={index}>{item.descripcion}</option>)
                    })
                    }
                  </NativeSelect>
                </FormControl>

              </Grid>

              <Grid item xs={12}>

                {/** Grupo */}
                <FormControl fullWidth>
                  <InputLabel variant="standard" htmlFor="grupo_id">
                    Grupo
                  </InputLabel>
                  <NativeSelect
                    value={animalCargaManual.grupo_id}
                    onChange={(e) => setAnimalCargaManual({...animalCargaManual, grupo_id: e.target.value})}
                    inputProps={{
                      name: 'grupo_id',
                      id: 'grupo_id',
                    }}
                  >
                    <option value={0} key={"Grupos"}>{"Grupos"}</option>
                    {grupos && grupos.map((item, index) => {
                      return (<option value={item.id} key={index}>{item.descripcion}</option>)
                    })
                    }
                  </NativeSelect>
                </FormControl>

              </Grid>

              <Grid item xs={12}>

                {/** Ano de nacimiento */}
                <TextField
                  value={animalCargaManual.nacimiento}
                  onChange={(e) => setAnimalCargaManual({...animalCargaManual, nacimiento: e.target.value})}
                  label="Ano de nacimiento"
                  variant="standard"
                  sx={{ width: '100%' }}
                  inputProps={{ inputMode: 'numeric'}}
                />

              </Grid>

              <Grid item xs={12}>

                {/** Grupo */}
                <FormControl fullWidth>
                  <InputLabel variant="standard" htmlFor="sex_id">
                    Sexos
                  </InputLabel>
                  <NativeSelect
                    value={animalCargaManual.sex_id}
                    onChange={(e) => setAnimalCargaManual({...animalCargaManual, sex_id: e.target.value})}
                    inputProps={{
                      name: 'sex_id',
                      id: 'sex_id',
                    }}
                  >
                    <option value={0} key={"Sexos"}>{"Sexos"}</option>
                    {sexos && sexos.map((item, index) => {
                      return (<option value={item.id} key={index}>{item.descripcion}</option>)
                    })
                    }
                  </NativeSelect>
                </FormControl>

              </Grid>

            </Grid>

          </Box>

        </DialogContent>
        <DialogActions>
          <Button onClick={() => {setOpenDialogCargaManual(false)}}>Cancelar</Button>
          <Button
            variant='contained'
            onClick={handleCargarAnimal}>Cargar animal</Button>
        </DialogActions>
      </Dialog>

    </Container>
  );

}

export default Lectura;