import axios from "axios";
import { addDBArray, addDBData, getDBData, initDB } from "./animalesDB";

export default class APIManager {
  static current = null;
  // static API_PATH = process.env.REACT_APP_API_URL;
  static API_PATH = 'http://192.168.0.119:82/ws'
  // static API_PATH = 'https://api.esquila.softguild.com.ar/ws'
  static PLUGIN_PATH = 'http://127.0.0.1:8080';
  // static PLUGIN_PATH = 'http://192.168.0.109:8080';

  constructor(dispatch, history) {
    this.myUUID = null;
    this.dispatch = dispatch;
    this.history = history;
    APIManager.current = this;
  }

  static sendRequest = (endpoint, data, callback, errorCallback) => {
    // const URL_PATH = this.API_PATH;
    const URL_PATH = localStorage.getItem('serverURL');
    let URL_FULL = "";

    URL_FULL = `${URL_PATH}/${endpoint}`;
    try {
      axios.post(URL_FULL, data, { withCredentials: false }).then((response) => {
        if (response && response.data) {
          callback(response.data);
        } else {
          if (!!errorCallback) {
            errorCallback(response);
          }
        }
      });
    } catch (error) {
      if (!!errorCallback) {
        errorCallback(error);
      }
    }

  };

  static printRequest = (data, callback, errorCallback) => {
    const URL_PATH = this.PLUGIN_PATH;
    const endpoint = 'print';
    let URL_FULL = "";

    URL_FULL = `${URL_PATH}/${endpoint}`;
    try {
      axios.post(URL_FULL, data, {
        withCredentials: false,
        headers: {
          // 'Accept': 'application/json',
          // 'Content-Type': 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'

        }
      }).catch(reason => {
        if (!!errorCallback) {
          errorCallback(reason);
        }
      }).then((response) => {
        if (response && response.data) {
          console.log('print plugin response', response)
          callback(response.data);
        } else {
          if (!!errorCallback) {
            errorCallback(response);
          }
        }
      });
    } catch (error) {
      if (!!errorCallback) {
        errorCallback(error);
      }
    }
  }

  static getParametros = (successCallback) => {
    console.log('getParametros');

    APIManager.sendRequest('ui/', { accion: 'getParametros' }, (response) => {
      if (response.success) {
        // Volvio ok
        // console.log('getParametros', response);
        localStorage.setItem('esquilas-db', JSON.stringify(response.data.esquilas));
        localStorage.setItem('estancias-db', JSON.stringify(response.data.estancias));
        localStorage.setItem('colores-db', JSON.stringify(response.data.colores));
        localStorage.setItem('letras-db', JSON.stringify(response.data.letras));
        localStorage.setItem('grupos-db', JSON.stringify(response.data.grupos));
        localStorage.setItem('secciones-db', JSON.stringify(response.data.secciones));
        localStorage.setItem('lotes-db', JSON.stringify(response.data.lotes));
        localStorage.setItem('sexos-db', JSON.stringify(response.data.sexos));
        localStorage.setItem('etapas-db', JSON.stringify(response.data.etapas));

        this.esquilas = response.data.esquilas;

        if (!!successCallback) {
          successCallback(response);
        }
      }
    });

  };

  static getAnimales = async (successCallback) => {
    console.log('getAnimales');

    // let animales = await db.getAll().then(items => items, reason => console.log('DB Error:', reason));
    let animales = await getDBData('animales');

    const parametros = JSON.parse(localStorage.getItem('parametros'));
    let maxId = null;

    if (!!animales && (animales.length > 0)) {
      const ordenadas = animales.sort((item1, item2) => (parseInt(item1.id) > parseInt(item2.id)) ? -1 : 1 );
      maxId = ordenadas[0].id;
    }

    APIManager.sendRequest('query/', {accion: 'getList',lista: 'animales', esquilaID: parametros?.esquila, idDesde: maxId, activo: true}, (response) => {
      if (response.success) {
        // Volvio ok
        let animalesTotales = [];
        // if (!!animales) {
          // animalesTotales = [...animales, ...response.data.listado];
        // } else {
          // animalesTotales = response.data.listado;
        // }

        // let i = 0;
        // window.animales = response.data.listado;
        addDBArray('animales',response.data.listado);

/*
        response.data.listado.forEach(animal => {
        // if (i < 10) {
          // console.log('agregando', animal);
          db.add(animal);
        // }
        //  i = i+1;
        });
 */
        // localStorage.setItem('animales-db', JSON.stringify(animalesTotales));
        if (!!successCallback) {
          successCallback(response);
        }
      }
    });

  };

  static getActividad = (successCallback) => {
    console.log('getActividad');

    const parametros = JSON.parse(localStorage.getItem('parametros'));
    const actividades = JSON.parse(localStorage.getItem('actividad-db'));
    let maxId = null;
    if (!!actividades && (actividades.length > 0)) {
      const ordenadas = actividades.sort((item1, item2) => (parseInt(item1.id) > parseInt(item2.id)) ? -1 : 1 );
      maxId = ordenadas[0].id;
    }
    APIManager.sendRequest('actividad/', {accion: 'getActividad', esquilaID: parametros?.esquila, idDesde: maxId}, (response) => {
      if (response.success) {
        // Volvio ok
        let actividadesTotales = [];
        if (!!actividades) {
          actividadesTotales = [...actividades, ...response.data.listado];
        } else {
          actividadesTotales = response.data.listado;
        }
        localStorage.setItem('actividad-db', JSON.stringify(actividadesTotales));
        if (!!successCallback) {
          successCallback(response);
        }
      }
    });

  };

  static setAnimales = (successCallback) => {
    console.log('setAnimales');

    const animalesDispositivo = localStorage.getItem('animales-dispositivo') ? JSON.parse(localStorage.getItem('animales-dispositivo')) : [];

    APIManager.sendRequest('animales/', {accion: 'setAnimales', animales: animalesDispositivo}, (response) => {
      if (response.success) {
        // Volvio ok

        // Quitamos los animales enviados de la lista del dispositivo
        const animalesDispositivoActuales = localStorage.getItem('animales-dispositivo') ? JSON.parse(localStorage.getItem('animales-dispositivo')) : [];
        const animalesSinEnviar = animalesDispositivoActuales.filter(item => !animalesDispositivo.find(animal => animal.senasa === item.senasa));
        console.log('Animales enviados:', animalesDispositivo, 'sin enviar:', animalesSinEnviar);
        localStorage.setItem('animales-dispositivo', animalesSinEnviar);

        if (!!successCallback) {
          successCallback(response);
        }
      }
    });
  }

  static setActividad = (successCallback) => {
    console.log('setActividad');
    const actividadDispositivo = localStorage.getItem('actividad-dispositivo') ? JSON.parse(localStorage.getItem('actividad-dispositivo')) : [];

    APIManager.sendRequest('actividad/', { accion: 'setActividad', actividad: actividadDispositivo }, (response) => {
      if (response.success) {
        // Volvio ok

        // Quitamos los animales enviados de la lista del dispositivo
        const actividadDispositivoActual = localStorage.getItem('actividad-dispositivo') ? JSON.parse(localStorage.getItem('actividad-dispositivo')) : [];
        const actividadSinEnviar = actividadDispositivoActual.filter(item =>
          !actividadDispositivo.find(actividad =>
            (actividad.animal_id === item.animal_id) && (actividad.datetime === item.datetime)
          )
        );
        console.log('Actividad enviada:', actividadDispositivo, 'sin enviar:', actividadSinEnviar);
        localStorage.setItem('actividad-dispositivo', actividadSinEnviar);

        if (!!successCallback) {
          successCallback(response);
        }
      }

    });

  }

  static sincronizarDatos = (actualizar) => {
    // Actualiza los animales y luego las actividades
    console.log('sincronizarDatos');
    this.setAnimales(() => {
      this.getAnimales(() => {
        this.setActividad(() => {
          this.getActividad();
        });
      });
    });

  }

}
