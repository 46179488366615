import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

/** MUI Components */
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';


/** MUI Icons */
import { IconButton, Stack, TextField } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

const Parametros = ({actualizar}) => {

  const navigate = useNavigate();


  /** localStorage Gets */
  let parametros = JSON.parse(localStorage.getItem('parametros'));
  const esquilas = JSON.parse(localStorage.getItem('esquilas-db'));
  const estancias = JSON.parse(localStorage.getItem('estancias-db'));

  if (!parametros) {
    parametros = {estancia: 0, serie: '', esquila: 0};
    localStorage.setItem('parametros', JSON.stringify(parametros));
  }

  /** Available states */
  const [estancia, setEstancia] = useState(parametros.estancia);
  const [serie, setSerie] = useState(parametros.serie);
  const [esquila, setEsquila] = useState(parametros.esquila);

  const [serverURL, setServerURL] = useState(!!localStorage.getItem('serverURL') ? localStorage.getItem('serverURL') : 'http://192.168.0.119:82/ws');

  /** Handlers */
  const handleDefinir = () => {

    let parametrosDefinir = {
      "esquila": esquila,
      "estancia": estancia,
      "serie": serie,
    };

    localStorage.setItem('parametros', JSON.stringify(parametrosDefinir));
    localStorage.setItem('serverURL', serverURL);
    actualizar();

    navigate('/');

  }

  return (
    <>
      <Box
        sx={{
          bgcolor: '#cfe8fc',
          height: '100vh',
          padding: '20px'
        }} >

        {/* <Stack direction='row' alignItems='center'>
          <IconButton onClick={() => navigate(-1)}><ArrowBack /></IconButton>
          <Typography variant="h6" gutterBottom>
            Parametros
          </Typography>
        </Stack> */}

        <Typography variant="h6" gutterBottom>
          Parametros
        </Typography>

        <Grid container spacing={2}>

          <Grid item xs={12}>
            <FormControl fullWidth >
              <InputLabel variant="standard" htmlFor="esquila">
                Esquila
              </InputLabel>
              <NativeSelect
                value={esquila}
                onChange={(e) => setEsquila(parametros['esquila'] = e.target.value)}
                inputProps={{
                  name: 'esquila',
                  id: 'esquila',
                }}
              >
                <option value={0} key={'esq_0'}>Esquilas</option>
                {esquilas && esquilas.map((item, index) => {
                  return (<option value={item.id} key={index}>{item.descripcion}</option>)
                })
                }
              </NativeSelect>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="estancia">
                Estancia
              </InputLabel>
              <NativeSelect
                value={estancia}
                onChange={(e) => setEstancia(parametros['estancia'] = e.target.value)}
                inputProps={{
                  name: 'estancia',
                  id: 'estancia',
                }}
              >
                <option value={0} key={'est_0'}>Estancias</option>
                {estancias && estancias.map((item, index) => {
                  return (<option value={item.id} key={index}>{item.descripcion}</option>)
                })
                }
              </NativeSelect>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField label="Serie" variant="standard"
              value={serie}
              onChange={(e) => setSerie(parametros['serie'] = e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
              <TextField label="Server URL" variant="standard" fullWidth
                value={serverURL}
                onChange={(e) => setServerURL(e.target.value)}
              />
          </Grid>
        </Grid>

        <Button variant='contained'
          sx={{
            marginTop: 10,
            width: '100%',
            height: '60px',
          }}
          onClick={handleDefinir}>
            Definir
        </Button>

      </Box>
    </>
  );

};

export default Parametros;