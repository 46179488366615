import { Outlet } from 'react-router-dom';
import TopAppBar from '../../components/AppBar/AppBar';
import BottomAppBar from '../../components/BottomAppBar/BottomAppBar';

const Layout = ({actualizar}) => {

  return (
    <>
      <TopAppBar />
      <Outlet />
      <BottomAppBar actualizar={actualizar} />
    </>
  );
}

export default Layout;