import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

/** MUI Components */
import { Box, Button } from "@mui/material";

/** MUI Icons */
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import SettingsIcon from '@mui/icons-material/Settings';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AutorenewIcon from '@mui/icons-material/Autorenew';

/** Helpers */
import APIManager from "../../helpers/apiManager";

/** Mock Data */
import animalesJSON from "../../helpers/data/animales.json";
import parametrosJSON from "../../helpers/data/parametros.json";
import actividadJSON from "../../helpers/data/actividad.json";
import { DialogTitle } from '@mui/material';
import { Dialog , DialogContentText , DialogContent , DialogActions } from '@mui/material';
// import { useIndexedDB } from 'react-indexed-db-hook';

const Home = ({actualizar}) => {
  const [openDialogReset, setOpenDialogReset] = useState(false);
  // const db = useIndexedDB('animales');

  const navigate = useNavigate();

  const buttonStyle = {
    marginTop: 5,
    width: '100%',
    height: '60px',
  };

  const handleGetAnimales = () => {

    APIManager.getAnimales(actualizar);
  };

  const handleGetParametros = () => {
    APIManager.getParametros(actualizar);
    // APIManager.getParametros(() => props.setGrupos(JSON.parse(localStorage.getItem('grupos-db'))));
  };

  const handleGetActividad = () => {
    APIManager.getActividad(actualizar);
  };

  const handleSincronizar = () => {
    APIManager.sincronizarDatos(actualizar);
  };

  const handleReset = () => {
    localStorage.clear();
    setOpenDialogReset(false);
  };

  return (
    <Box
      sx={{
        bgcolor: '#cfe8fc',
        height: '100vh',
        padding: '20px'
      }} >
{/*
      <Button variant='contained' sx={buttonStyle}
        startIcon={<CloudDownloadIcon/>}
        onClick={handleGetAnimales}
      > Actualizar animales </Button>

      <Button variant='contained' sx={buttonStyle}
        startIcon={<CloudDownloadIcon/>}
        onClick={handleGetActividad}
      > Actualizar actividad </Button>
 */}
      <Button variant='contained' sx={buttonStyle}
        startIcon={<CloudDownloadIcon/>}
        onClick={handleGetParametros}
      > Actualizar parametros </Button>

      <Button variant='contained' sx={buttonStyle}
        startIcon={<SettingsIcon/>}
        onClick={() => navigate('/parametros')}
      > Definir Parametros </Button>

      <Button variant='contained' sx={buttonStyle}
        startIcon={<AutorenewIcon/>}
        onClick={handleSincronizar}
      > Sincronizar Datos </Button>

      <Button variant='contained' sx={{...buttonStyle, backgroundColor: 'green'}}
        startIcon={<AddCircleIcon/>}
        onClick={() => navigate('/lectura')}
      > Iniciar lecturas </Button>

      <Button variant='outlined' sx={buttonStyle}
        startIcon={<AddCircleIcon/>}
        onClick={() => setOpenDialogReset(true)}
      > Reset datos Locales </Button>
      {/** Dialog Confirmacion */}
      <Dialog
        open={openDialogReset}
        onClose={handleReset}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
            {`¿Confirma?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Confirma eliminar los datos locales?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialogReset(false)} autoFocus>Cancelar</Button>
          <Button onClick={handleReset}>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>

  );

};

export default Home;