import { Fragment, useEffect, useState } from 'react';

/** MUI Components */
import AppBar from '@mui/material/AppBar';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const BottomAppBar = ({actualizar}) => {

  /** localStorage Gets */
  const etapas = JSON.parse(localStorage.getItem('etapas-db'));
  const etapa = JSON.parse(localStorage.getItem('etapa'));

  /** Available states */
  const [etapaSeleccionada, setEtapaSeleccionada] = useState(etapa ? etapa : {id: 0, name: ''});

  /** Handlers */
  const handleChange = (e) => {
    const value = e.target.value;
    const etapaSelected = etapas.find(etapa => etapa.id == value);
    localStorage.setItem('etapa', JSON.stringify(etapaSelected));
    actualizar();
    setEtapaSeleccionada(etapaSelected);
  };

  /** Side effects */
  useEffect(() => {
    renderContent();
  },[etapaSeleccionada])

  /** Render Content */
  const renderContent = () => {

    return (
      <AppBar position="fixed" color="default"
        sx={{ top: 'auto', bottom: 0, alignItems: 'center' }}
      >
        <ToggleButtonGroup
          color="primary"
          value={etapaSeleccionada.id}
          onChange={handleChange}
          exclusive
          aria-label="Platform"
        >
          {etapas && etapas.map((item, index) => {
            return (
            <ToggleButton key={index}
              id={item.id}
              value={item.id}
              >
                {item.name}
            </ToggleButton>)
          })}
        </ToggleButtonGroup>
      </AppBar>
    );

  }

  return (
    <Fragment>
      {renderContent()}
    </Fragment>
  );

};

export default BottomAppBar;