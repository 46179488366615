import { BrowserRouter, Routes, Route } from "react-router-dom";

/** Styles */
import './App.css';

/** Views */
import Layout from './views/Layout';
import Parametros from './views/Parametros';
import Lectura from './views/Lectura';
import Home from './views/Home';
import { useInterval } from "./helpers/useInterval";
import APIManager from "./helpers/apiManager";
import { useEffect, useState } from "react";
import { initDB } from "./helpers/animalesDB";
// import { initDB } from "react-indexed-db-hook";
// import { dbConfig } from "./helpers/dbConfig";

// initDB(dbConfig);
initDB();

const App = () => {
  /** localStorage Gets */
  const [esquilas, setEsquilas] = useState([]);
  const [parametros, setParametros] = useState({});
  const [estancias, setEstancias] = useState([]);
  const [colores, setColores] = useState([]);
  const [letras, setLetras] = useState([]);
  const [grupos, setGrupos] = useState([]);
  const [secciones, setSecciones] = useState([]);
  const [sexos, setSexos] = useState([]);
  const [etapas, setEtapas] = useState([]);
  const [etapa, setEtapa] = useState([]);
  // const [animales, setAnimales] = useState([]);
  // const [animalesDispositivo, setAnimalesDispositivo] = useState([]);
  // const [actividad, setActividad] = useState([]);
  // const [actividadDispositivo, setActividadDispositivo] = useState([]);

/*
  useInterval(() => {
    // Intervalo de actualizacion
    APIManager.sincronizarDatos();
  }, 1 * 60 * 1000); // periodo de 1 minuto
  // }, 10 * 1000); // periodo de 10 segundos
 */

  const actualizarDesdeLocalStorage = () => {
    setEsquilas(JSON.parse(localStorage.getItem('esquilas-db')));
    setParametros(JSON.parse(localStorage.getItem('parametros')));
    setEstancias(JSON.parse(localStorage.getItem('estancias-db')));
    setColores(JSON.parse(localStorage.getItem('colores-db')));
    setLetras(JSON.parse(localStorage.getItem('letras-db')));
    setGrupos(JSON.parse(localStorage.getItem('grupos-db')));
    setSecciones(JSON.parse(localStorage.getItem('secciones-db')));
    setSexos(JSON.parse(localStorage.getItem('sexos-db')));
    setEtapas(JSON.parse(localStorage.getItem('etapas-db')));
    setEtapa(JSON.parse(localStorage.getItem('etapa')));
/*
    let storageData = localStorage.getItem('animales-db');
    if (storageData) { setAnimales(JSON.parse(storageData)) };

    storageData = localStorage.getItem('animales-dispositivo');
    if (storageData) { setAnimalesDispositivo(JSON.parse(storageData)) };

    storageData = localStorage.getItem('actividad-db');
    if (storageData) { setActividad(JSON.parse(storageData)) };

    storageData = localStorage.getItem('actividad-dispositivo');
    if (storageData) { setActividadDispositivo(JSON.parse(storageData)) };
     */
  }

  useEffect(() => {
    actualizarDesdeLocalStorage();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home actualizar={actualizarDesdeLocalStorage} />} />
        <Route element={<Layout actualizar={actualizarDesdeLocalStorage} />}>
          <Route path="parametros" element={<Parametros actualizar={actualizarDesdeLocalStorage} />}/>
          <Route path="lectura" element={
            <Lectura actualizarApp={actualizarDesdeLocalStorage} parametros={parametros} estancias={estancias}
              letras={letras} colores={colores} grupos={grupos} secciones={secciones} sexos={sexos}
              etapas={etapas} etapa={etapa}
              // animales={animales} animalesDispositivo={animalesDispositivo}
              // actividad={actividad} actividadDispositivo={actividadDispositivo}
          />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );

};

export default App;
