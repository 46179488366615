import APIManager from "./apiManager";

export const imprimirTicket = (animal, tipoDeTicket, repetida, grupos) => {
  // Armamos el Ticket
  let ticketData = [];
  const grupo = animal.grupo_id ? grupos.find(item => item.id === animal.grupo_id) : {id: null, descripcion: ''};
  // let command = { command: '', body: '' };

  ticketData.push({ command: 'add', body: "[C]<barcode type='128' width='220' height='50' text='below'>" + animal.senasa + "</barcode>" });
  ticketData.push({ command: 'add', body: "[C]" });
  ticketData.push({ command: 'add', body: "[C]Grupo: " + grupo.descripcion });
  ticketData.push({ command: 'add', body: "[C]" });
  ticketData.push({ command: 'add', body: "[C]" + (new Date).toLocaleString() });
  if (repetida) {
    ticketData.push({ command: 'add', body: "[C]Repetida!!" });
  }
  // ticketData.push({ command: 'add', body: "[C]Fin ticket" });

  // Mandamos a imprimir con el plugin "ESCPOS Print Server" (Softguild)
  APIManager.printRequest(ticketData, (data) => {

  }, (response) => {
    console.log('Error:', response);
  })
}

