export const initDB = () => {
  return new Promise((resolve) => {
    // open the connection
    let request = indexedDB.open('esquilaDB',1);

    request.onupgradeneeded = () => {
      let db = request.result;

      // if the data object store doesn't exist, create it
      if (!db.objectStoreNames.contains('animales')) {
        db.createObjectStore('animales', { keyPath: 'id' });
      }
      // no need to resolve here
    };

    request.onsuccess = () => {
      resolve(true);
    };

    request.onerror = () => {
      resolve(false);
    };
  });
};

export const addDBData = (storeName, data) => {
  return new Promise((resolve) => {
    let request = indexedDB.open('esquilaDB');

    request.onsuccess = () => {
      let db = request.result;
      const tx = db.transaction(storeName, 'readwrite');
      const store = tx.objectStore(storeName);
      store.add(data);
      resolve(data);
    };

    request.onerror = () => {
      const error = request.error?.message
      if (error) {
        resolve(error);
      } else {
        resolve('Unknown error');
      }
    };
  });
};

export const addDBArray = (storeName, dataArray) => {
  return new Promise((resolve) => {
    let request = indexedDB.open('esquilaDB');

    request.onsuccess = () => {
      let db = request.result;
      const tx = db.transaction(storeName, 'readwrite');
      const store = tx.objectStore(storeName);
      dataArray.forEach(data => {
        store.add(data);
      });
      resolve(dataArray);
    };

    request.onerror = () => {
      const error = request.error?.message
      if (error) {
        resolve(error);
      } else {
        resolve('Unknown error');
      }
    };
  });
};

export const getDBData = (storeName) => {
  return new Promise((resolve) => {
    let request = indexedDB.open('esquilaDB');

    request.onsuccess = () => {
      let db = request.result;
      const tx = db.transaction(storeName, 'readonly');
      const store = tx.objectStore(storeName);
      const res = store.getAll();
      res.onsuccess = () => {
        resolve(res.result);
      };
    };
  });
};